/*!
Template Name: Dashbyte - Premium Admin & Dashboard Template
Author: Themepixels
Version: 1.0.0
Website: http://themepixels.me/demo/dashbyte
Contact: themepixels@gmail.com
*/

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;600&display=swap');

// Variables & Mixins
@import "variables";
@import "mixins";

// Bootstrap Framework
@import "bootstrap/scss/bootstrap";

// Components
@import "components/accordion";
@import "components/alerts";
@import "components/avatars";
@import "components/badge";
@import "components/breadcrumbs";
@import "components/buttons";
@import "components/card";
@import "components/dropdown";
@import "components/images";
@import "components/list-group";
@import "components/markers";
@import "components/modal";
@import "components/nav";
@import "components/offcanvas";
@import "components/pagination";
@import "components/popovers";
@import "components/progress";
@import "components/toasts";
@import "components/tables";
@import "components/forms";

// Panels
@import "panels/header";
@import "panels/sidebar";
@import "panels/sidebar-prime";
@import "panels/sidebar-dark";
@import "panels/main";

// Dashboard
@import "dashboard/sales";
@import "dashboard/analytics";
@import "dashboard/crypto";
@import "dashboard/finance";
@import "dashboard/helpdesk";
@import "dashboard/events";
@import "dashboard/storage";
@import "dashboard/product";

// Apps
@import "apps/app";
@import "apps/file-manager";
@import "apps/email";
@import "apps/chat";
@import "apps/contact";
@import "apps/calendar";
@import "apps/tasks";
@import "apps/music";
@import "apps/video";

// Pages
@import "pages/user";
@import "pages/auth";
@import "pages/error";
@import "pages/other";
@import "pages/docs";
@import "pages/landing";

// Plugins
@import "plugins/scrollbar";
@import "plugins/gridjs";
@import "plugins/select2";
@import "plugins/tagsinput";
@import "plugins/rangeslider";
@import "plugins/jqueryui";
@import "plugins/colorpicker";
@import "plugins/wizards";
@import "plugins/parsley";
@import "plugins/prismjs";
@import "plugins/quill";
@import "plugins/leaflet";
@import "plugins/vmap";
@import "plugins/react-datepicker";

// Utilities
@import "utils";

// Skin
@import "dark";



// .bd-example {
//     border-width: 1px;
//     border-top-left-radius: 0.25rem;
//     border-top-right-radius: 0.25rem;
//     padding: 0.5rem;
//     border: solid #12b19c;
// }

.category-title:hover {
    color: rgb(0 180 152);
    cursor: default;
}

.fullwidth-img {
    width: auto;
}

// loader css //
.parentContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15rem;
}

.displayCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.childContainer {
    width: auto;
    height: auto;
}

.productCheckbox input[type=checkbox] {
    width: 20px;
    height: 30px;
    margin: auto;
    outline: 1px solid rgb(13, 12, 12);
}


.dashboardCard {
    background: #fff;
    border-radius: 0.6em;
    overflow: hidden;
    cursor: pointer;
    // box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
    transition: all ease 200ms;
}

.dashboardCard:hover {
    transform: scale(1.03);
    // box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.sidebar .nav-sidebar .nav-link.active {
    color: #506fd9;
    font-weight: 400;
}

.parentDiv {
    display: flex;
    justify-content: center; 
    width: 100%; 
  }
  
  .parentDiv video {
    max-width: 100%; 
    max-height: 100%;
  }